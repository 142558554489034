import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import SEO from 'components/seo'
import { CONTACT_FORM_LINK } from 'utils/constants'
import { graphql, Link } from 'gatsby'
import renderOnClient from 'components/renderOnClient'
import { jsonId, composeClasses } from 'utils'
import CarouselComponent from 'components/carousel'
import { isMobile } from 'react-device-detect'
import whatsappIcon from '../images/whatsappIcon.svg'
import mailIcon from '../images/mailIcon.svg'
import linkIcon from 'images/link-icon.svg'
import ftImageGrey from 'images/ftImageGrey.png'

import * as homeStyles from 'styles/home.module.css'
import HelmetExport from 'react-helmet'

export const query = graphql`
  query {
    wordPress {
      pages(where: { title: "Home" }) {
        edges {
          node {
            homepageFieldGroup {
              homepageHeaderTitle
              homepageHeaderSubtitle
              homepageHeaderSubtext
              homepageWhatsappText
              hompageWhatsappLink
              homepageLearnAboutGoogleText
              homepageHeaderImages {
                desktopImage {
                  sourceUrl
                }
                mobileImage {
                  sourceUrl
                }
              }
              ourClientsContent {
                ourClientLink
                ourClientsLogo {
                  sourceUrl
                }
              }
              ourClientsHeader
              ourClientsSubheader
              ourClientsTestimonials {
                clientLogo {
                  sourceUrl
                }
                clientTestimonialSender
                clientTestimonialSenderPosition
                clientTestimonialText
              }
              showClientTestimonials
              professionalServicesHeader
              professionalServicesSubheader
              professionalServicesDescription
              professionalServicesContent {
                professionalServiceButtonText
                professionalServiceButtonLink
                professionalServiceDescription
                professionalServiceName
              }
              whyDeimosHeader
              whyDeimosSubheader
              whyDeimosBeforeHeader
              whyDeimosBeforeDeimosText
              whyDeimosBeforeDeimosImage {
                sourceUrl
              }
              whyDeimosAfterDeimosHeader
              whyDeimosAfterDeimosText
              whyDeimosAfterDeimosImage {
                sourceUrl
              }
              whyDeimosGetInTouchButtonText
              goingGoogleHeader
              goingGoogleSubheader
              goingGoogleSection1Header
              goingGoogleSection1Text
              goingGoogleSection1Image {
                sourceUrl
              }
              goingGoogleSection2Header
              goingGoogleSection2Text
              goingGoogleSection2Image {
                sourceUrl
              }
              goGoogleButtonText
              goGoogleButtonLink
              ourTechStackHeader
              ourTechStackSubheader
              ourStrategicPartnersHeader
              ourStrategicPartnersDesktopImage {
                sourceUrl
              }
              ourStrategicPartnersMobileImage {
                sourceUrl
              }
              ourStrategicPartnersButtonDesktopText
              ourStrategicPartnersButtonMobileText
              ourStrategicPartnersButtonLink
              ourTechStackContent {
                engineeringHeader
                engineeringStackDesktopImage {
                  sourceUrl
                }
                engineeringStackMobileImage {
                  sourceUrl
                }
              }
              checkVacanciesDescription
              checkVacanciesButtonDesktopText
              checkVacanciesButtonMobileText
              checkVacanciesButtonLink
              aboutUsHeader
              aboutUsSubheader
              aboutUsDescription
              aboutUsButtonText
              aboutUsButtonLink
              aboutUsMap {
                sourceUrl
              }
              whatSetsUsApartHeader
              whatSetsUsApartSubheader
              whatSetsUsApartDescription
              whatSetsUsApartButtonText
              whatSetsUsApartImage {
                sourceUrl
              }
              successStoriesHeader
              successStoriesSubheader
              successStoriesContent {
                clientLogo {
                  altText
                  sourceUrl
                }
                clientStoryTitle
                clientStorySubtitle
                clientStory
                clientStoryButtonText
                clientStoryButtonLink {
                  link
                }
              }
              contactFormText
              contactFormButtonText
            }
          }
        }
      }
    }
  }
`

const HomeContent = ({ data }) => {
  const homePageData = data.homepageFieldGroup

  return (
    <>
      {/* Hardcoded as it is temporary */}
      <div className={homeStyles.homepageTextBanner}>
        <div className={homeStyles.homepageTextBannerContainer}>
          <img
            alt='client logo'
            src={ftImageGrey}
            width='180' />
          <p>Deimos ranked Africa’s Fastest Growing IT & Software Company 2024.</p>
          <a
            href='https://www.ft.com/content/a1bc5d2e-046e-499b-b27e-4d057f9d8477'
            rel='noopener noreferrer'
            target={'_blank'}>Read Here</a>
        </div>
      </div>
      <section className={homeStyles.horizontalWrapper}>
        <div className={homeStyles.hero}>
          <div className={homeStyles.heroTextWrapper}>
            <div className={homeStyles.heroTextContainer}>
              <h2>{homePageData.homepageHeaderTitle}</h2>
              <p className={homeStyles.heroSubtitle}>
                {homePageData.homepageHeaderSubtitle}
              </p>
              <p className={homeStyles.heroSubtext}>
                {homePageData.homepageHeaderSubtext}
              </p>
            </div>

            <div className={homeStyles.heroLinks}>
              <Link
                className={homeStyles.heroAboutGoogleText}
                target={'_blank'}
                to={CONTACT_FORM_LINK}
              >
                <img
                  alt='mailIcon'
                  height='24'
                  src={mailIcon}
                  width='24' />
                {homePageData.homepageLearnAboutGoogleText}
              </Link>

              <span className={homeStyles.heroLinksSpacer}>or</span>

              <a
                className={homeStyles.heroWhatsappButton}
                href={homePageData.hompageWhatsappLink}
                rel='noopener noreferrer'
                target={'_blank'}
              >
                <img
                  alt='whatsappIcon'
                  height='24'
                  src={whatsappIcon}
                  width='24' />
                {homePageData.homepageWhatsappText}
              </a>
            </div>
          </div>

          <div className={homeStyles.heroImageContainer}>
            <img
              alt={`post image`}
              className={homeStyles.heroImageDesktop}
              height='437'
              src={homePageData.homepageHeaderImages[0].desktopImage.sourceUrl}
              width='377'
            />

            <img
              alt={`post image`}
              className={homeStyles.heroImageMobile}
              height='437'
              src={homePageData.homepageHeaderImages[0].mobileImage.sourceUrl}
              width='377'
            />
          </div>
        </div>
      </section>

      <section className={homeStyles.clientWrapper}>
        <div className={homeStyles.clientsContainer}>
          <h1 className={homeStyles.clientHeaderWrapper}>
            {homePageData.ourClientsHeader}
          </h1>
          <h1 className={homeStyles.clientsubHeaderWrapper}>
            {homePageData.ourClientsSubheader}
          </h1>

          <div className={homeStyles.clientImgWrapper}>
            {homePageData.ourClientsContent.map((item, index) => (
              <div
                className={homeStyles.clientLogo}
                key={index.toString()}>
                <img
                  alt='client logo'
                  height='57'
                  src={item.ourClientsLogo.sourceUrl}
                  width='180' />
              </div>
            ))}
          </div>
          {homePageData.showClientTestimonials &&
            (
              <div className={homeStyles.testimonialContent}>
                <div className={homeStyles.testimonialSection}>
                  <CarouselComponent
                    homepageCarousel
                    items={homePageData.ourClientsTestimonials}
                    itemsToShow={1}
                    render={(item, index) => {
                      return (
                        <div
                          className={homeStyles.testimonialContentWrapper}
                          key={index}
                          style={
                            isMobile && homePageData.ourClientsTestimonials.length
                              ? { maxWidth: '100%' }
                              : ''
                          }
                        >
                          <div className={homeStyles.imgContainer}>
                            <img
                              alt='client logo'
                              height='57'
                              src={item.clientLogo.sourceUrl}
                              width='200'
                            />
                          </div>
                          <div className={homeStyles.clientStyle}>
                            <p className={homeStyles.testimonialDescription}>
                              {item.clientTestimonialText}
                            </p>
                            <div>
                              <p className={homeStyles.testimonialSender}>
                                {item.clientTestimonialSender}
                              </p>
                              <p className={homeStyles.testimonialSenderPosition}>
                                {item.clientTestimonialSenderPosition}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
            )
          }
        </div>
      </section>

      <section
        className={composeClasses(
          homeStyles.sectionWrapper,
          homeStyles.professionalServicesWrapper
        )}
      >
        <div className={homeStyles.sectionContainer}>
          <p className={homeStyles.sectionHeader}>
            {homePageData.professionalServicesHeader}
          </p>
          <h2 className={homeStyles.sectionSubHeader}>
            {homePageData.professionalServicesSubheader}
          </h2>
          <p className={homeStyles.professionalServicesDesc}>
            {homePageData.professionalServicesDescription}
          </p>
        </div>

        <div className={homeStyles.professionalServicesCardContainer}>
          {homePageData.professionalServicesContent.map(
            (
              {
                professionalServiceButtonText,
                professionalServiceButtonLink,
                professionalServiceDescription,
                professionalServiceName
              },
              index
            ) => (
              <div
                className={homeStyles.professionalServiceCard}
                key={index.toString()}
              >
                <div className={homeStyles.cardHeaderContainer}>
                  <h3 className={homeStyles.cardHeader}>
                    {professionalServiceName}
                  </h3>
                </div>
                <div className={homeStyles.professionalServicesList}>
                  {parse(professionalServiceDescription)}
                </div>

                <div className={homeStyles.professionalServiceButtonWrapper}>
                  <Link
                    className={homeStyles.primaryButton}
                    to={professionalServiceButtonLink}
                  >
                    <span>{professionalServiceButtonText}</span>
                  </Link>
                </div>
              </div>
            )
          )}
        </div>
      </section>

      <section className={homeStyles.whyDeimosWrapper}>
        <div className={homeStyles.whyDeimosHeaderContainer}>
          <p className={homeStyles.whyDeimosHeader}>
            {homePageData.whyDeimosHeader}
          </p>
          <h2 className={homeStyles.whyDeimosSubHeader}>
            {homePageData.whyDeimosSubheader}
          </h2>
        </div>

        <div className={homeStyles.whyDeimosBodyContainer}>
          <div className={homeStyles.whyDeimosBeforeContainer}>
            <div className={homeStyles.whyDeimosBefore}>
              <div className={homeStyles.whyDeimosBeforeContent}>
                <h3 className={homeStyles.whyDeimosBeforeHeader}>
                  {homePageData.whyDeimosBeforeHeader}
                </h3>
                <div className={homeStyles.whyDeimosBeforeList}>
                  {parse(homePageData.whyDeimosBeforeDeimosText)}
                </div>
              </div>
              <div className={homeStyles.whyDeimosBeforeImage}>
                <img
                  alt='Sad Face'
                  height='266'
                  src={homePageData.whyDeimosBeforeDeimosImage.sourceUrl}
                  width='333' />
              </div>
            </div>
          </div>

          <div className={homeStyles.whyDeimosAfterContainer}>
            <div className={homeStyles.whyDeimosAfter}>
              <div className={homeStyles.whyDeimosAfterImage}>
                <img
                  alt='Happy Face'
                  height='266'
                  src={homePageData.whyDeimosAfterDeimosImage.sourceUrl}
                  width='333' />
              </div>
              <div className={homeStyles.whyDeimosAfterContent}>
                <h3 className={homeStyles.whyDeimosAfterHeader}>
                  {homePageData.whyDeimosAfterDeimosHeader}
                </h3>
                <div className={homeStyles.whyDeimosAfterText}>
                  {parse(homePageData.whyDeimosAfterDeimosText)}
                </div>
              </div>
            </div>
          </div>
          <div className={homeStyles.whyDeimosButtonContainer}>
            <a
              className={composeClasses(homeStyles.primaryButton, homeStyles.whyDeimosButton)}
              href={CONTACT_FORM_LINK}
              target='_blank'
            >
              {homePageData.whyDeimosGetInTouchButtonText}
            </a>

          </div>
        </div>
      </section>

      <section className={homeStyles.goingGoogle}>
        <div className={homeStyles.goingGoogleContainer}>
          <div>
            <p className={homeStyles.goingGoogleHeader}>
              {homePageData.goingGoogleHeader}
            </p>
            <h2 className={homeStyles.goingGoogleSubheader}>
              {homePageData.goingGoogleSubheader}
            </h2>
          </div>

          <div className={homeStyles.goingGoogleSections}>
            <div className={composeClasses(homeStyles.goingGoogleSection)}>
              <div className={homeStyles.goingGoogleSectionInfo}>
                <h3>{homePageData.goingGoogleSection1Header}</h3>
                <div className={homeStyles.goingGoogleSection1Text}>
                  {parse(homePageData.goingGoogleSection1Text)}
                </div>
              </div>
              <div className={homeStyles.goingGoogleImageContainer}>
                <img
                  alt='rainy cloud'
                  height='266'
                  src={homePageData.goingGoogleSection1Image.sourceUrl}
                  width='333'
                />
              </div>
            </div>

            <div className={composeClasses(homeStyles.goingGoogleSection)}>
              <div className={homeStyles.goingGoogleImageContainer}>
                <img
                  alt='sunny cloud'
                  height='266'
                  src={homePageData.goingGoogleSection2Image.sourceUrl}
                  width='333'
                />
              </div>

              <div className={homeStyles.goingGoogleSectionInfo}>
                <h3>{homePageData.goingGoogleSection2Header}</h3>
                <div className={homeStyles.goingGoogleSection2Text}>
                  {parse(homePageData.goingGoogleSection2Text)}
                </div>
              </div>
            </div>
          </div>

          <div className={homeStyles.goGoogleButtonContainer}>
            <Link
              className={composeClasses(
                homeStyles.primaryButton,
                homeStyles.goGoogleButton
              )}
              to={homePageData.goGoogleButtonLink}
            >
              {homePageData.goGoogleButtonText}
            </Link>
          </div>
        </div>
      </section>

      <section
        className={composeClasses(
          homeStyles.sectionWrapper,
          homeStyles.ourTechStackWrapper
        )}
      >
        <div className={homeStyles.sectionContainer}>
          <p className={homeStyles.sectionHeader}>
            {homePageData.ourTechStackHeader}
          </p>
          <h2 className={homeStyles.sectionSubHeader}>
            {homePageData.ourTechStackSubheader}
          </h2>
        </div>

        <div className={homeStyles.ourStrategicPartners}>
          <h3>{homePageData.ourStrategicPartnersHeader}</h3>
          <img
            alt={homePageData.ourStrategicPartnersHeader}
            className={homeStyles.ourTechStackDesktopImage}
            height='69'
            src={homePageData.ourStrategicPartnersDesktopImage.sourceUrl}
            width='985'
          />

          <img
            alt={homePageData.ourStrategicPartnersHeader}
            className={homeStyles.ourTechStackMobileImage}
            height='172'
            src={homePageData.ourStrategicPartnersMobileImage.sourceUrl}
            width='223'
          />
          <Link
            className={composeClasses(
              homeStyles.primaryButton,
              homeStyles.ourStrategicPartnersButton
            )}
            to={homePageData.ourStrategicPartnersButtonLink}
          >
            <span className={homeStyles.desktopText}>
              {homePageData.ourStrategicPartnersButtonDesktopText}
            </span>
            <span className={homeStyles.mobileText}>
              {homePageData.ourStrategicPartnersButtonMobileText}
            </span>
          </Link>
        </div>
        <div>
          {homePageData.ourTechStackContent.map(
            (
              {
                engineeringHeader,
                engineeringStackDesktopImage,
                engineeringStackMobileImage
              },
              index
            ) => (
              <div
                className={homeStyles.engineeringStack}
                key={index.toString()}
              >
                <h3>{engineeringHeader}</h3>
                <img
                  alt={engineeringHeader}
                  className={homeStyles.ourTechStackDesktopImage}
                  height='69'
                  src={engineeringStackDesktopImage.sourceUrl}
                  width='1070'
                />

                <img
                  alt={engineeringHeader}
                  className={homeStyles.ourTechStackMobileImage}
                  height='106'
                  src={engineeringStackMobileImage.sourceUrl}
                  width='290'
                />
              </div>
            )
          )}
        </div>

        <p className={homeStyles.checkVacanciesDescription}>
          {homePageData.checkVacanciesDescription}
        </p>
        <a
          className={composeClasses(
            homeStyles.primaryButton,
            homeStyles.checkVacanciesButton
          )}
          href={homePageData.checkVacanciesButtonLink}
          rel='noopener noreferrer'
          target='_blank'
        >
          <img
            alt='link icon'
            height='16'
            src={linkIcon}
            width='16' />
          <span className={homeStyles.desktopText}>
            {homePageData.checkVacanciesButtonDesktopText}
          </span>
          <span className={homeStyles.mobileText}>
            {homePageData.checkVacanciesButtonMobileText}
          </span>
        </a>
      </section>

      <section className={homeStyles.aboutUsWrapper}>
        <div className={homeStyles.aboutUsContainer}>
          <div className={homeStyles.aboutUsContent}>
            <p className={homeStyles.sectionHeader}>
              {homePageData.aboutUsHeader}
            </p>
            <h2 className={homeStyles.sectionSubHeader}>
              {homePageData.aboutUsSubheader}
            </h2>
            <div className={homeStyles.aboutUsDescription}>
              {parse(homePageData.aboutUsDescription)}
            </div>
            <Link
              className={homeStyles.primaryButton}
              to={homePageData.aboutUsButtonLink}
            >
              {homePageData.aboutUsButtonText}
            </Link>
          </div>

          <div className={homeStyles.aboutUsMap}>
            <img
              alt='Deimos Map'
              height='353'
              src={homePageData.aboutUsMap.sourceUrl}
              width='533' />
          </div>
        </div>
      </section>

      <section>
        <div className={homeStyles.whatSetsUsApartWrapper}>
          <div className={homeStyles.whatSetsUsApartBody}>
            <div className={homeStyles.whatSetsUsApartImage}>
              <img
                alt='What Sets Us Apart'
                height='473'
                src={homePageData.whatSetsUsApartImage.sourceUrl}
                width='477' />
            </div>
            <div className={homeStyles.whatSetsUsApartContent}>
              <h2 className={homeStyles.whatSetsUsApartHeader}>{homePageData.whatSetsUsApartHeader}</h2>
              <h3 className={homeStyles.whatSetsUsApartSubheader}>{homePageData.whatSetsUsApartSubheader}</h3>
              <div className={homeStyles.whatSetsUsApartDescription}>
                {parse(homePageData.whatSetsUsApartDescription)}
              </div>
              <a
                className={homeStyles.whatSetsUsApartButton}
                href={CONTACT_FORM_LINK}
                target='_blank'>
                {homePageData.whatSetsUsApartButtonText}
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className={composeClasses(homeStyles.sectionWrapper, homeStyles.successStoriesWrapper)}>
        <div className={homeStyles.sectionContainer}>
          <p className={homeStyles.sectionHeader}>{homePageData.successStoriesHeader}</p>
          <h2 className={homeStyles.sectionSubHeader}>{homePageData.successStoriesSubheader}</h2>
        </div>

        <div className={homeStyles.customerSuccessWrapper}>
          <div className={homeStyles.customerSuccessStoriesContainer}>
            {homePageData.successStoriesContent.map((item, index) => (
              <div
                className={homeStyles.customerSuccessContainer}
                key={index.toString()}
              >
                <div className={homeStyles.customerSuccessImageWrapper}>
                  <img
                    alt='clientLogo'
                    height='103'
                    src={item.clientLogo.sourceUrl}
                    width='195' />
                </div>
                <div className={homeStyles.successStoryBody}>
                  <div className={homeStyles.successStoryDesc}>
                    <h3>{item.clientStoryTitle}</h3>
                    <p>{parse(item.clientStorySubtitle)}</p>
                  </div>
                  <div className={homeStyles.customerSuccessDivDesktop}>
                    <span className={homeStyles.successListContainer}>{parse(item.clientStory)}</span>
                  </div>
                  <div className={homeStyles.customerSuccessDivMobile}>
                    <span className={homeStyles.successListContainer}>{parse(item.clientStory.substring(0, 115) + '...')}</span>
                  </div>
                </div>
                <div className={homeStyles.customerSuccessButtonWrapper}>
                  <a
                    href={item.clientStoryButtonLink.link}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <span
                      className={homeStyles.primaryButton}>
                      {item.clientStoryButtonText}
                    </span>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={homeStyles.contactFormWrapper}>
        <div className={homeStyles.contactFormContainer}>
          <div className={homeStyles.contactFormTextContainer}>
            <h2 className={homeStyles.contactFormText}>
              {homePageData.contactFormText}
            </h2>
          </div>
          <a
            className={homeStyles.contactFormButton}
            href={CONTACT_FORM_LINK}
            target='_blank'
          >
            {homePageData.contactFormButtonText}
          </a>
        </div>
      </section>
    </>
  )
}

HomeContent.propTypes = {
  data: PropTypes.object.isRequired
}

const HomePage = ({ data }) => {
  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'NewsArticle',
          contactPoint: [
            {
              '@type': 'ContactPoint',
              contactType: 'customer service',
              email: 'info@deimos.co.za',
              telephone: '+27 87 354 1286'
            }
          ],
          legalName: 'Deimos',
          logo: 'https://admin.deimos.io/wp-content/uploads/deimosLogo.svg',
          sameAs: [
            'https://web.facebook.com/DeimosCloud/',
            'https://twitter.com/deimoscloud',
            'https://www.linkedin.com/company/deimos-cloud',
            'https://www.instagram.com/deimoscloud/'
          ],
          url: 'https://deimos.io'
        })}
      </HelmetExport>
      <SEO title='Deimos Official Website' />
      <div className={homeStyles.wrapper}>
        <HomeContent data={data.wordPress.pages.edges[0].node} />
      </div>
    </>
  )
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired
}

export default renderOnClient(HomePage)
